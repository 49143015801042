define('m09/components/arctwo-d', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'arctwo-d',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = x3d.get('bauteile').findBy('id', name);

      var cords;
      var x = 0,
          y = 0,
          z = 0;

      if (name === 'lastenPfeil4') {
        x = bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('vorholzlaenge') / 10 + bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('durchbruchlaenge') / 10 - 1.5;
        y = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y') / 20;
        z = 0;
        cords = x + ' ' + y + ' ' + z;
      } else if (name === 'lastenPfeil3') {
        x = bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('vorholzlaenge') / 10 + 1.5;
        y = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y') / 20;
        z = 0;
        cords = x + ' ' + y + ' ' + z;
      }

      return cords;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values;

      return values;
    }).property('model.firstObject.hWinkel', 'model.firstObject.vWinkel'),

    anfangsWinkel: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value;

      if (name === 'lastenPfeil4') {
        value = -20 * Math.PI / 180;
      } else if (name === 'lastenPfeil3') {
        value = 160 * Math.PI / 180;
      }
      return value;
    }).property('model.firstObject.hWinkel', 'model.firstObject.vWinkel'),

    endWinkel: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value;

      if (name === 'lastenPfeil4') {
        value = 20 * Math.PI / 180;
      } else if (name === 'lastenPfeil3') {
        value = 200 * Math.PI / 180;
      }
      return value;
    }).property('model.firstObject.hWinkel', 'model.firstObject.vWinkel'),

    radius: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = 2;

      return value;
    }).property('model.firstObject.hWinkel', 'model.firstObject.hWinkelAktiv'),

    emissivecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('momentlastAktiv') === true && (name === 'lastenPfeil3' || name === 'lastenPfeil4')) {
        emsvcolor = '1 0 0';
      }

      if (name === 'koordinatensystem') {
        emsvcolor = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
      }

      return emsvcolor;
    }).property('model.firstObject.querkraftAktiv', 'model.firstObject.momentlastAktiv')

  });

  exports['default'] = Transform;

});