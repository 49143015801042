define('m09/models/bauteile', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var bauteile = DS['default'].Model.extend({

		MatKeyTimberElementHT: DS['default'].attr('string'),
		FKLKeyTimberElementHT: DS['default'].attr('string'),
		TimberElementHT_b: DS['default'].attr('string'),
		TimberElementHT_h: DS['default'].attr('string'),
		TimberElementHT_Predrilled: DS['default'].attr('boolean', {
			defaultValue: function defaultValue() {
				return 'false';
			}
		}),
		HtRiegel: DS['default'].attr('string'),
		MatKeyTimberElementNT: DS['default'].attr('string'),
		FKLKeyTimberElementNT: DS['default'].attr('string'),
		TimberElementNT_b: DS['default'].attr('string'),
		TimberElementNT_h: DS['default'].attr('string'),
		TimberElementNT_Predrilled: DS['default'].attr('boolean', {
			defaultValue: function defaultValue() {
				return 'false';
			}
		}),
		Torsionssteif: DS['default'].attr('string', {
			defaultValue: function defaultValue() {
				return 'true';
			}
		}),
		Min_sSchrOK: DS['default'].attr('string', {
			defaultValue: function defaultValue() {
				return '0';
			}
		}),
		Min_sSchrUK: DS['default'].attr('string', {
			defaultValue: function defaultValue() {
				return '0';
			}
		}),
		Predrilled: DS['default'].attr('string', {
			defaultValue: function defaultValue() {
				return 'false';
			}
		}),
		QuerschnittsID: DS['default'].attr('string', {
			defaultValue: function defaultValue() {
				return '1';
			}
		})

	});

	bauteile.reopenClass({
		FIXTURES: [{
			id: 1,
			MatKeyTimberElementHT: "0",
			FKLKeyTimberElementHT: "5",
			TimberElementHT_b: "20",
			TimberElementHT_h: "20",
			TimberElementHT_Predrilled: false,
			HtRiegel: true,
			MatKeyTimberElementNT: "0",
			FKLKeyTimberElementNT: "5",
			TimberElementNT_b: "20",
			TimberElementNT_h: "20",
			TimberElementNT_Predrilled: false,
			Torsionssteif: true,
			Min_sSchrOK: "0",
			Min_sSchrUK: "0",
			Predrilled: false,
			QuerschnittsID: "1"
		}]
	});

	exports['default'] = bauteile;

});