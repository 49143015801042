define('m09/controllers/queranschluss', ['exports', 'ember', 'ember-validations', 'ember-i18n'], function (exports, Ember, EmberValidations, ember_i18n) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend(EmberValidations['default'], {

    displayErrors: true,
    openFromFile: false,

    gewindestahlstangen: false,
    disableEindringtiefe: false,

    eindringtiefetmp: 1,
    anzahlbauteiletmp: 1,

    vmdurchmesser: "",
    eindringtiefe: "",
    anzahlvmreihen: "",
    abstandobererrand: "",
    abstandvmreihen: "",
    abstandaussen: "",
    abstandvmgruppen: "",
    anzahlbauteile: true,
    vmittelart: 0,

    ttDurchmesserVM: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.1 - 999.9 [cm]";
    }),
    ttEindringtiefeVM: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.1 - 999.9 [cm]";
    }),
    ttAnzahlReihen: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "1 - 999 [cm]";
    }),
    ttAbstandReihen: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.1 - 999.9 [cm]";
    }),
    ttAbstandObererRand: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.1 - 999.9 [cm]";
    }),
    ttAussen: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.1 - 999.9 [cm]";
    }),
    ttAbstandGruppen: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('wertebereich') + "0.1 - 999.9 [cm]";
    }),

    i18n: Ember['default'].inject.service(),

    validations: {
      vmdurchmesser: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.9
        }
      },
      eindringtiefe: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.9
        }
      },
      anzahlvmreihen: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.9
        }
      },
      abstandobererrand: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.9
        }
      },
      abstandaussen: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.9
        }
      },
      abstandvmreihen: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.9
        }
      },
      abstandvmgruppen: {
        numericality: {
          greaterThanOrEqualTo: 0.1,
          lessThanOrEqualTo: 999.9
        }
      }
    },

    bauteilanzahlen: [{
      name: "einseitig",
      value: true
    }, {
      name: "zweiseitig",
      value: false
    }],

    vmittelarten: [{
      name: "Nagel/Schrauben: Holz-Holz oder HWS-Holz",
      value: 0
    }, {
      name: "Nägel: Stahlblech-Holz",
      value: 1
    }, {
      name: "Stabdübel oder Bolzen",
      value: 2
    }, {
      name: "Dübel besondere Bauart",
      value: 3
    }, {
      name: "Schrauben, Gewinde- oder Stahlstangen von unten",
      value: 4
    }],

    watchNumericalInputs: (function () {

      var self = this;

      if (!self.openFromFile) {

        if (self.get('vmdurchmesser').indexOf(",") !== -1) {
          self.set('vmdurchmesser', self.get('vmdurchmesser').replace(",", "."));
        }

        if (self.get('eindringtiefe').indexOf(",") !== -1) {
          self.set('eindringtiefe', self.get('eindringtiefe').replace(",", "."));
        }

        if (self.get('anzahlvmreihen').indexOf(",") !== -1) {
          self.set('anzahlvmreihen', self.get('anzahlvmreihen').replace(",", "."));
        }

        if (self.get('abstandobererrand').indexOf(",") !== -1) {
          self.set('abstandobererrand', self.get('abstandobererrand').replace(",", "."));
        }

        if (self.get('abstandaussen').indexOf(",") !== -1) {
          self.set('abstandaussen', self.get('abstandaussen').replace(",", "."));
        }

        if (self.get('abstandvmreihen').indexOf(",") !== -1) {
          self.set('abstandvmreihen', self.get('abstandvmreihen').replace(",", "."));
        }

        if (self.get('abstandvmgruppen').indexOf(",") !== -1) {
          self.set('abstandvmgruppen', self.get('abstandvmgruppen').replace(",", "."));
        }
      }
    }).observes('vmdurchmesser', 'eindringtiefe', 'anzahlvmreihen', 'abstandobererrand', 'abstandaussen', 'abstandvmreihen', 'abstandvmgruppen'),

    watchComboboxes: (function () {

      var self = this;

      if (self.get('vmittelart') == 4) {
        self.set('gewindestahlstangen', true);
        self.set('disableEindringtiefe', true);
        console.log(self.gewindestahlstangen);
        //self.set('eindringtiefetmp', self.get('eindringtiefe'));
        self.set('eindringtiefe', '0.0');
        //self.set('anzahlbauteiletmp', self.get('anzahlbauteile'));
        self.set('anzahlbauteile', false);
        self.set('validations.eindringtiefe.numericality.greaterThanOrEqualTo', 0);
        self.send('validation');
      } else {
        self.set('gewindestahlstangen', false);
        console.log(self.gewindestahlstangen);
        self.set('disableEindringtiefe', false);
        self.set('validations.eindringtiefe.numericality.greaterThanOrEqualTo', 0.1);
        self.send('validation');
        //self.set('eindringtiefe', self.eindringtiefetmp);
        //self.set('anzahlbauteile', self.anzahlbauteiletmp);
      }

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);

      var queranschluss = this.controllerFor('application').get('model').queranschluss.objectAt(0);

      queranschluss.set('TypNrAnschluss', this.get('vmittelart'));
      queranschluss.set('einseitig', this.get('anzahlbauteile'));
    }).observes('vmittelart', 'anzahlbauteile'),

    watchVerstaerkungAusserhalb: (function () {

      var queranschluss = this.controllerFor('application').get('model').queranschluss.objectAt(0);

      queranschluss.set('verstaerkungAusserhalb', this.get('vmnurausserhalb'));
    }).observes('vmnurausserhalb'),

    setValues: function setValues(values) {

      var self = this;

      if (self.debug) {
        console.log("values in queranschluss: ");
        console.log(values);
      }

      var htbreiteEvent = { target: { name: "htbreite" } };
      var hthoeheEvent = { target: { name: "hthoehe" } };

      this.set('openFromFile', true);

      this.set('vmittelart', values.TypNrAnschluss);
      this.set('vmdurchmesser', values.dConnectors);
      this.set('eindringtiefe', values.tConnectors);
      this.set('anzahlbauteile', values.einseitig);
      this.set('anzahlvmreihen', values.n_vertikal);
      this.set('abstandvmreihen', values.a1_Connectors);
      this.set('abstandobererrand', values.h_1);
      this.set('abstandaussen', values.a_r);
      this.set('vmnurausserhalb', values.verstaerkungAusserhalb);
      this.set('abstandvmgruppen', values.l_g);

      this.send('validation');

      this.set('openFromFile', false);
    },

    actions: {

      validation: function validation(value, event) {

        var self = this;

        if (!self.openFromFile) {

          //this.setX3D(value, event);

        }

        self.validate().then(function () {
          // all validations pass

        })['catch'](function () {
          // any validations fail

        })['finally'](function () {

          var validation = self.get('isValid');
          var application = self.controllerFor('application');
          if (validation === true) {
            self.set('displayErrors', false);
            application.set('queranschlussInvalid', false);

            var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
            applicationdata.set('treeLoaded', false);
            applicationdata.set('initialized', false);
            applicationdata.set('pdfErstellt', false);

            var downloadcenter = self.controllerFor('downloadcenter');
            downloadcenter.set('pdfErstellt', false);

            application.set('verbindungsmittelInvalid', true);

            var queranschluss = self.controllerFor('application').get('model').queranschluss.objectAt(0);

            queranschluss.set('dConnectors', parseFloat(self.get('vmdurchmesser')).toFixed(1));
            queranschluss.set('tConnectors', parseFloat(self.get('eindringtiefe')).toFixed(1));
            queranschluss.set('n_vertikal', parseFloat(self.get('anzahlvmreihen')).toFixed(1));
            queranschluss.set('h_1', parseFloat(self.get('abstandobererrand')).toFixed(1));
            queranschluss.set('a_r', parseFloat(self.get('abstandaussen')).toFixed(1));
            queranschluss.set('a1_Connectors', parseFloat(self.get('abstandvmreihen')).toFixed(1));
            queranschluss.set('l_g', parseFloat(self.get('abstandvmgruppen')).toFixed(1));
          } else {
            self.set('displayErrors', true);
            application.set('queranschlussInvalid', true);
          }
        });
      }
    }

  });

});