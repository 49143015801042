define('m09/models/x3ddefault', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  var X3D = DS['default'].Model.extend({
    modul: DS['default'].attr(''),
    transformHelper: DS['default'].attr(''),
    viewpointHelper: DS['default'].attr(''),

    zeigeKoordinatensystem: DS['default'].attr('boolean', { defaultValue: false }),

    bauteile: DS['default'].hasMany('bauteil', { async: false }),

    resetFieldOfView: DS['default'].attr('boolean', { defaultValue: false }),

    hWinkel: DS['default'].attr('number', { defaultValue: 90 }),
    vWinkel: DS['default'].attr('number', { defaultValue: 0 }),

    lastViewpoint: DS['default'].attr('string', { defaultValue: 'viewpointDefault' }),
    aktuellerSchraubenTyp: DS['default'].attr('string', { defaultValue: 'standardSchraube' }),

    querkraft: DS['default'].attr('number', { defaultValue: 0 }),
    momentlast: DS['default'].attr('number', { defaultValue: 0 }),

    querkraftAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    momentlastAktiv: DS['default'].attr('boolean', { defaultValue: false }),

    querkraftEingetragen: DS['default'].attr('boolean', { defaultValue: false }),
    momentlastEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    htbreiteAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    htbreiteEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    hthoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    hthoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    vorholzlaengeAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    vorholzlaengeEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    abstandauflagerAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    abstandauflagerEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    lichterabstandAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    lichterabstandEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    resthoeheobenAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    resthoeheobenEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    resthoeheuntenAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    resthoeheuntenEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    durchbruchhoeheAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    durchbruchhoeheEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    durchbruchlaengeAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    durchbruchlaengeEingetragen: DS['default'].attr('boolean', { defaultValue: false }),

    schraubenlisteAktualisiert: DS['default'].attr('boolean', { defaultValue: false }),

    hWinkelAktiv: DS['default'].attr('boolean', { defaultValue: false }),
    vWinkelAktiv: DS['default'].attr('boolean', { defaultValue: false }),

    skalierungsfaktor: DS['default'].attr('number', { defaultValue: function defaultValue() {
        return 1;
      } }),
    startDistance: DS['default'].attr('number', { defaultValue: 12.5 }),
    aktuelleDistance: DS['default'].attr('number', { defaultValue: 12.5 }),
    textRefresh: DS['default'].attr('number', { defaultValue: 0 }),

    xMin: DS['default'].attr('number', { defaultValue: 99999 }),

    randAbstand1: DS['default'].attr('number', { defaultValue: 0 }),
    randAbstand2: DS['default'].attr('number', { defaultValue: 0 }),
    schraubenAbstand1: DS['default'].attr('number', { defaultValue: 0 }),
    schraubenAbstand2: DS['default'].attr('number', { defaultValue: 0 }),

    abstaendeAnzeigen: DS['default'].attr('boolean', { defaultValue: true }),
    ergebnisGeladen: DS['default'].attr('boolean', { defaultValue: false }),

    istGitterModell: DS['default'].attr('boolean', { defaultValue: false })

  });

  exports['default'] = X3D;

});