define('m09/components/schraube-n', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schrauben = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraube-n',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    screwcount: 0,

    center: (function () {
      var values = '0 0 0';
      return values;
    }).property(''),

    translation: (function () {
      var values = '0 0 0';
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    rotation: (function () {
      var values = '0 0 0 0';
      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    schraubenListe: (function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var count = 0;
      var schrListe = Ember['default'].Set.create();

      if (this.get('model').objectAt(0).get('ergebnisGeladen')) {

        bauteile.forEach(function (item) {

          // if (item.get('typ') === x3d.get('aktuellerSchraubenTyp') && Number(item.get('translations').objectAt(0).get('x')) < Number(bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('vorholzlaenge'))){
          if (item.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
            count = count + 1;
            schrListe.add(item);
          }
        });

        this.setAbstaende(schrListe);
      }

      this.set('screwcount', schrListe.length);

      console.log('screwcount: ' + this.get('screwcount'));

      return schrListe.toArray();
    }).property('model.firstObject.schraubenlisteAktualisiert'),

    setAbstaende: function setAbstaende(schrliste) {
      var x3d = this.get('model').objectAt(0);

      var tBreite = x3d.get('bauteile').findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z');

      var liste1 = [];

      var randAbstand1 = 0,
          randAbstand2 = 0,
          schraubenAbstand1 = 0,
          schraubenAbstand2 = 0;

      var xMin = x3d.get('xMin');

      schrliste.forEach(function (item) {

        var xPos = item.get('translations').objectAt(0).get('x');
        if (xPos < xMin) {
          xMin = xPos;
          x3d.set('xMin', xMin);
        }

        var zPos = item.get('translations').objectAt(0).get('z');

        liste1.push(zPos);
      });

      liste1.sort(function (a, b) {
        return a - b;
      });

      randAbstand1 = -tBreite / 2 - liste1[0];
      randAbstand2 = tBreite / 2 - liste1[liste1.length - 1];

      x3d.set('randAbstand1', Math.abs(randAbstand1));
      x3d.set('randAbstand2', Math.abs(randAbstand2));

      x3d.set('schraubenAbstand1', schraubenAbstand1);
      x3d.set('schraubenAbstand2', schraubenAbstand2);
    },

    abstaendeSchraubenListe: (function () {
      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');
      var schrListe = Ember['default'].Set.create();
      var xMin = x3d.get('xMin');

      bauteile.forEach(function (item) {
        if (item.get('typ') === x3d.get('aktuellerSchraubenTyp') && Number(item.get('translations').objectAt(0).get('x')) === xMin) {
          schrListe.add(item);
        }
      });

      console.log(schrListe);

      return schrListe.toArray();
    }).property('model.firstObject.schraubenlisteAktualisiert'),

    schraubenabstaende1: (function () {
      if (this.get('model').objectAt(0).get('schraubenAbstand1') !== 0) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenabstand1', 'model.firstObject.schraubenlisteAktualisiert'),

    schraubenabstaende2: (function () {
      if (this.get('model').objectAt(0).get('schraubenAbstand2') !== 0) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenabstand2', 'model.firstObject.schraubenlisteAktualisiert'),

    gekreuzteSchrauben: (function () {
      if (this.get('model').objectAt(0).get('schraubenGekreuzt')) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.schraubenGekreuzt'),

    zAbstaendeAnzeigen: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');

      if (x3d.get('abstaendeAnzeigen') === true && x3d.get('ergebnisGeladen') === true) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.abstaendeAnzeigen'),

    abstaendeAnzeigen: (function () {
      if (this.get('model').objectAt(0).get('abstaendeAnzeigen') && this.get('model').objectAt(0).get('ergebnisGeladen')) {
        return true;
      } else {
        return false;
      }
    }).property('model.firstObject.abstaendeAnzeigen', 'this.screwcount'),

    zMinSchraube: (function () {

      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');

      var minimum = null;
      var idSchraube = '';

      bauteile.forEach(function (item, i) {
        if (item.get('typ') === x3d.get('aktuellerSchraubenTyp')) {

          if (minimum === null || item.get('translations').objectAt(0).get('z') < minimum) {
            minimum = Number(item.get('translations').objectAt(0).get('z'));
            idSchraube = item.id;
          }
        }
      });

      return idSchraube;
    }).property('model.firstObject.schraubenlisteAktualisiert'),

    zMinSchraube2: (function () {

      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');

      var minimum = null;
      var max = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 2;
      var temp = 9999;
      var idSchraube = '';

      var schrListe = [];

      bauteile.forEach(function (item) {
        if (item.get('typ') === x3d.get('aktuellerSchraubenTyp')) {

          if (minimum === null || minimum < item.get('translations').objectAt(0).get('z') && item.get('translations').objectAt(0).get('z') < max && item.get('translations').objectAt(0).get('z') !== temp) {
            minimum = Number(item.get('translations').objectAt(0).get('z'));
            temp = item.get('translations').objectAt(0).get('z');
            idSchraube = item.id;
          } else {
            if (item.get('translations').objectAt(0).get('z') < minimum) {
              minimum = Number(item.get('translations').objectAt(0).get('z'));
            } else if (item.get('translations').objectAt(0).get('z') < max && item.get('translations').objectAt(0).get('z') > max) {
              max = item.get('translations').objectAt(0).get('z');
            }
          }
        }
      });

      return idSchraube;
    }).property('model.firstObject.schraubenlisteAktualisiert'),

    zMaxSchraube: (function () {

      var x3d = this.get('model').objectAt(0);
      var bauteile = this.get('model').objectAt(0).get('bauteile');

      var maximum = -Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 2;
      var idSchraube = '';

      bauteile.forEach(function (item) {
        if (item.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
          if (item.get('translations').objectAt(0).get('z') > maximum) {
            maximum = Number(item.get('translations').objectAt(0).get('z'));
            idSchraube = item.id;
          }
        }
      });

      console.log('zMax: ' + idSchraube);

      return idSchraube;
    }).property('model.firstObject.schraubenlisteAktualisiert'),

    ansichtLinks: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }
      return value;
    }).property('model.firstObject.lastViewpoint'),

    ansichtFront: (function () {
      var value = false;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint'),

    ansichtOben: (function () {
      var value = false;
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = true;
      }

      return value;
    }).property('model.firstObject.lastViewpoint'),

    moreThenTwoScrews: (function () {

      if (this.get('screwcount') > 2) {
        return true;
      } else {
        return false;
      }
    }).property('screwcount')

  });

  exports['default'] = Schrauben;

});