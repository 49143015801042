define('m09/components/schraub-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schraube = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraub-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    center: (function () {
      var values = '0 0 0';
      var name = this.get('name');

      return values;
    }).property(''),

    translation: (function () {
      var values = '0 0 0';

      var name = this.get('name');

      var bauteile = this.get('model').objectAt(0).get('bauteile');

      var x = bauteile.findBy('id', name).get('translations').objectAt(0).get('x') / 10;
      var y = bauteile.findBy('id', name).get('translations').objectAt(0).get('y') / 10;
      var z = bauteile.findBy('id', name).get('translations').objectAt(0).get('z') / 10;

      values = x + ' ' + y + ' ' + z;

      // console.log('translations: '+values);

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    // Rotation in der XY-Ebene, um die z-Achse
    rotation: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var drehwinkelYAchse = bauteile.findBy('id', name).get('rotations').objectAt(0).get('y');
      var drehwinkelZAchse = bauteile.findBy('id', name).get('rotations').objectAt(0).get('z');
      var values = '';

      var xWertRotationsachse = Math.sin(drehwinkelYAchse);
      var zWertRotationsachse = Math.cos(drehwinkelYAchse);

      values = xWertRotationsachse + ' 0 ' + zWertRotationsachse + ' ' + drehwinkelZAchse;

      // console.log('rotations: '+values);

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt')
  });

  exports['default'] = Schraube;

});