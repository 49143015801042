define('m09/models/queranschluss', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var queranschluss = DS['default'].Model.extend({

		TypNrAnschluss: DS['default'].attr('string'),
		dConnectors: DS['default'].attr('string'),
		tConnectors: DS['default'].attr('string'),
		einseitig: DS['default'].attr('boolean'),
		l_g: DS['default'].attr('string'),
		n_vertikal: DS['default'].attr('string'),
		h_1: DS['default'].attr('string'),
		a_r: DS['default'].attr('string'),
		abstandvmreihen: DS['default'].attr('string'),
		verstaerkungAusserhalb: DS['default'].attr('boolean'),
		Kragarm: DS['default'].attr('boolean'),
		FromButtom: DS['default'].attr('boolean'),
		a1_Connectors: DS['default'].attr('string'),
		a2_Connectors: DS['default'].attr('string'),
		n_horizontal: DS['default'].attr('string')

	});

	queranschluss.reopenClass({
		FIXTURES: [{
			id: 1,
			TypNrAnschluss: "1",
			dConnectors: "0",
			tConnectors: "0",
			einseitig: true,
			l_g: 0,
			n_vertikal: "0",
			h_1: "0",
			a_r: "0",
			abstandvmreihen: "0",
			verstaerkungAusserhalb: false,
			Kragarm: false,
			FromButtom: true,
			a1_Connectors: "0",
			a2_Connectors: "0",
			n_horizontal: "2"

		}]
	});

	exports['default'] = queranschluss;

});