define('m09/models/durchbr', ['exports', 'ember-data'], function (exports, DS) {

  'use strict';

  exports['default'] = DS['default'].Model.extend({
    istKreis: DS['default'].attr('boolean'),
    kreissegmente: DS['default'].attr('number'),
    d: DS['default'].attr('number'),
    dbhoehe: DS['default'].attr('number'),
    resthoeheunten: DS['default'].attr('number'),
    resthoeheoben: DS['default'].attr('number'),
    vorholzlaenge: DS['default'].attr('number'),
    abstandauflager: DS['default'].attr('number'),
    durchbruchlaenge: DS['default'].attr('number')
  });

});