define('m09/components/indexedlinese-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'indexedlinese-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    objektSkalierung: 1,

    translation: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var bezug = this.get('typ');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;

      var item = bauteile.findBy('id', name);

      if (name === 'koordinatensystem') {
        x = bauteile.findBy('id', name).get('translations').objectAt(0).get('x');
        y = bauteile.findBy('id', name).get('translations').objectAt(0).get('y');
        z = bauteile.findBy('id', name).get('translations').objectAt(0).get('z');
        cords = x + ' ' + y + ' ' + z;
      }

      if (name === 'lastenPfeil1') {
        x = bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('vorholzlaenge') / 10 - item.get('translations').objectAt(0).get('x');
        y = 1 + bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('resthoeheunten') / 10;
        z = item.get('translations').objectAt(0).get('z');
        cords = x + ' ' + y + ' ' + z;
      }

      if (name === 'lastenPfeil2') {
        x = bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('vorholzlaenge') / 10 + bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('durchbruchlaenge') / 10 + item.get('translations').objectAt(0).get('x');
        y = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('resthoeheoben') / 10 - 1;
        z = item.get('translations').objectAt(0).get('z');
        cords = x + ' ' + y + ' ' + z;
      }

      if (name === 'dachgeometrie' && (typ === 'satteldach' || typ === 'pultdach' || typ === 'walmdach' || typ === 'breite' || typ === 'laenge' || typ === 'hoehe' || typ === 'firstueberstand' || typ === 'traufeueberstand' || typ === 'giebelueberstand' || typ === 'giebellaenge')) {
        x = 0;
        y = -item.get('gebaeudemasse').objectAt(0).get('firsthoehe') / (this.objektSkalierung * 3);
        z = 0;
        cords = x + ' ' + y + ' ' + z;
      }

      return cords;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '0 0 0 0';

      var item = bauteile.findBy('id', name);

      if (name === 'koordinatensystem') {
        values = item.get('rotations').objectAt(0).get('x') + " " + item.get('rotations').objectAt(0).get('y') + " " + item.get('rotations').objectAt(0).get('z') + " " + item.get('rotations').objectAt(0).get('winkel');
      }

      if (name === 'lastenPfeil1') {
        values = item.get('rotations').objectAt(0).get('x') + " " + item.get('rotations').objectAt(0).get('y') + " " + item.get('rotations').objectAt(0).get('z') + " " + item.get('rotations').objectAt(0).get('winkel');
      }

      if (name === 'hWinkel') {
        values = '1 0 0 -1.5707';
      }

      return values;
    }).property('model.firstObject.transformHelper'),

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var hwinkel = Number(x3d.get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;

      var x = 0;
      var y = 0;
      var z = 0;

      // var skalierungsfaktor = x3d.get('skalierungsfaktor');
      var skalierungsfaktor = 1;

      var cords;

      var p1 = new Object();
      var p2 = new Object();

      var drehachsen = new Object();
      var drehwinkel = new Object();

      var w90Grad = 89 * Math.PI / 180;
      var w180Grad = 180 * Math.PI / 180;

      var item = x3d.get('bauteile').findBy('id', name);

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var korrektur = 0.05;

      if (item.get('typ') === 'box') {

        x = item.get('boxsizes').objectAt(0).get('x') / 10;
        y = item.get('boxsizes').objectAt(0).get('y') / 10;
        z = item.get('boxsizes').objectAt(0).get('z') / 10;

        if (typ === 'kontur' && name === 'traeger') {
          cords = this.getTraegerKoordinaten();
        } else if (typ === 'kontur' && name === 'auflager') {
          cords = this.getAuflagerKoordinaten();
        }

        if (name === 'traeger' && typ === 'faserrichtung') {

          skalierungsfaktor = 1;
          var delta = 0.15;

          x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 10;
          y = 0.15;
          z = item.get('boxsizes').objectAt(0).get('z') / 20;

          cords = x - 0.85 + ' ' + (y + delta * 2) + ' ' + z + ', ' + (x - 1) + ' ' + (y + delta) + ' ' + z + ', ' + (x - 0.15) + ' ' + (y + delta) + ' ' + z + ', ' + (x - 0.15 - delta) + ' ' + y + ' ' + z;
        }

        switch (typ) {
          case 'breite':

            p1.x = 0;
            p1.y = -(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10) - abstandVomBauteil - hoeheMasskette;
            p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

            p2.x = 0;
            p2.y = p1.y;
            p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              drehwinkel.x = 0;
              drehwinkel.y = -w90Grad;
              drehwinkel.z = w90Grad;

              p1.x = -korrektur - hoeheMasskette;
              p1.y = 0;

              p2.x = p1.x;
              p2.y = 0;
            } else {
              drehwinkel.x = 0;
              drehwinkel.y = -w90Grad;
              drehwinkel.z = w180Grad;
            }
            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
            break;
          case 'hoehe':

            p1.x = -abstandVomBauteil - hoeheMasskette;
            p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
            p1.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

            p2.x = p1.x;
            p2.y = 0;
            p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              drehwinkel.x = -w90Grad;
              drehwinkel.y = 0;
              drehwinkel.z = w90Grad;

              p1.x = 0;
              p1.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20) - abstandVomBauteil - hoeheMasskette;

              p2.x = 0;
              p2.z = p1.z;
            } else {
              drehwinkel.x = 0;
              drehwinkel.y = 0;
              drehwinkel.z = w90Grad;
            }

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
            break;
          case 'resthoeheoben':

            p1.x = 0;
            p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
            p1.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

            p2.x = 0;
            p2.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', name).get('durchbr').objectAt(0).get('resthoeheoben') / 10;
            p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              drehwinkel.x = -w90Grad;
              drehwinkel.y = 0;
              drehwinkel.z = w90Grad;
            } else {
              drehwinkel.x = 0;
              drehwinkel.y = 0;
              drehwinkel.z = w90Grad;
            }

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
            break;
          case 'resthoeheunten':

            p1.x = 0;
            p1.y = 0 + bauteile.findBy('id', name).get('durchbr').objectAt(0).get('resthoeheunten') / 10;
            p1.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

            p2.x = 0;
            p2.y = 0;
            p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              drehwinkel.x = -w90Grad;
              drehwinkel.y = 0;
              drehwinkel.z = w90Grad;
            } else {
              drehwinkel.x = 0;
              drehwinkel.y = 0;
              drehwinkel.z = w90Grad;
            }

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
            break;
          case 'durchbruchhoehe':

            p1.x = 0;
            p1.y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10 - bauteile.findBy('id', name).get('durchbr').objectAt(0).get('resthoeheoben') / 10;
            p1.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

            p2.x = 0;
            p2.y = 0 + bauteile.findBy('id', name).get('durchbr').objectAt(0).get('resthoeheunten') / 10;
            p2.z = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);

            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              drehwinkel.x = -w90Grad;
              drehwinkel.y = 0;
              drehwinkel.z = w90Grad;
            } else {
              drehwinkel.x = 0;
              drehwinkel.y = 0;
              drehwinkel.z = w90Grad;
            }

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
            break;
          case 'auflagerabstand':

            p1.x = bauteile.findBy('id', name).get('durchbr').objectAt(0).get('vorholzlaenge') / 10;
            p1.y = -(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10);
            p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

            p2.x = bauteile.findBy('id', 'auflager').get('translations').objectAt(0).get('x') / 10 - 0.01;
            p2.y = -(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10);
            p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              drehwinkel.x = w90Grad;
              drehwinkel.y = 0;
              drehwinkel.z = 0;

              p1.y = 0;
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              p2.y = 0;
              p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
            } else {
              drehwinkel.x = w180Grad;
              drehwinkel.y = 0;
              drehwinkel.z = 0;
            }

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
            break;
          case 'vorholzlaenge':

            p1.x = bauteile.findBy('id', name).get('durchbr').objectAt(0).get('vorholzlaenge') / 10;
            p1.y = -(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10) - abstandVomBauteil - hoeheMasskette;
            p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

            p2.x = 0;
            p2.y = p1.y;
            p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              drehwinkel.x = w90Grad;
              drehwinkel.y = 0;
              drehwinkel.z = 0;

              p1.y = 0;
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20 + abstandVomBauteil + hoeheMasskette;

              p2.y = 0;
              p2.z = p1.z;
            } else {
              drehwinkel.x = w180Grad;
              drehwinkel.y = 0;
              drehwinkel.z = 0;
            }

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
            break;
          case 'durchbruchlaenge':

            p1.x = bauteile.findBy('id', name).get('durchbr').objectAt(0).get('vorholzlaenge') / 10 + bauteile.findBy('id', name).get('durchbr').objectAt(0).get('durchbruchlaenge') / 10;
            p1.y = -(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10);
            p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

            p2.x = bauteile.findBy('id', name).get('durchbr').objectAt(0).get('vorholzlaenge') / 10 + 0.01;
            p2.y = -(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10);
            p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              drehwinkel.x = w90Grad;
              drehwinkel.y = 0;
              drehwinkel.z = 0;

              p1.y = 0;
              p1.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;

              p2.y = 0;
              p2.z = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20;
            } else {
              drehwinkel.x = w180Grad;
              drehwinkel.y = 0;
              drehwinkel.z = 0;
            }

            cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
            break;}
      }

      if (item.get('typ') === 'verbindungsmittel' && x3d.get('ergebnisGeladen')) {

        var xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
        var yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
        var zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

        var schraubenlaenge = (item.get('screwProperties').objectAt(0).get('length') + item.get('screwProperties').objectAt(0).get('headheight')) / 10;
        var alpha = item.get('rotations').objectAt(0).get('z');
        var beta = item.get('rotations').objectAt(0).get('y');

        if (typ === 'xPos') {

          p1.x = Number(bauteile.findBy('id', 'traeger').get('durchbr').objectAt(0).get('vorholzlaenge')) / 10;
          p1.z = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 20;

          p2.x = Number(bauteile.findBy('id', name).get('translations').objectAt(0).get('x')) / 10;
          p2.z = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 20;

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            p1.y = 0;
            p2.y = 0;

            p1.z = p2.z + 2 * abstandVomBauteil + 2 * hoeheMasskette;
            p2.z = p1.z;
            drehwinkel.x = w90Grad;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          } else {
            p1.y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10) - 2 * abstandVomBauteil - 2 * hoeheMasskette;
            p2.y = p1.y;

            drehwinkel.x = w180Grad;
            drehwinkel.y = 0;
            drehwinkel.z = 0;
          }
          // console.log('p1.x: '+p1.x+'; p1.y: '+p1.y+'; p1.z: '+p1.z);
          // console.log('p2.x: '+p2.x+'; p2.y: '+p2.y+'; p2.z: '+p2.z);
        }

        if (typ === 'zPos') {

          var zPos = Number(item.get('translations').objectAt(0).get('z'));
          var vorgaenger = -Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 20;

          bauteile.forEach(function (bauteil) {
            if (bauteil.get('typ') === x3d.get('aktuellerSchraubenTyp')) {
              if (Number(item.get('translations').objectAt(0).get('z')) > Number(bauteil.get('translations').objectAt(0).get('z'))) {
                vorgaenger = Number(bauteil.get('translations').objectAt(0).get('z')) / 10;
              }
            }
          });

          p1.z = vorgaenger;
          p2.z = Number(bauteile.findBy('id', name).get('translations').objectAt(0).get('z')) / 10;

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            p1.x = 0;
            p2.x = 0;
            p1.y = 0;
            p2.y = 0;
            drehwinkel.x = -w90Grad;
            drehwinkel.y = w90Grad;
            drehwinkel.z = 0;
          } else {
            p1.x = Number(bauteile.findBy('id', name).get('translations').objectAt(0).get('x')) / 10;
            p2.x = Number(bauteile.findBy('id', name).get('translations').objectAt(0).get('x')) / 10;
            p1.y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10);
            p2.y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10);
            drehwinkel.x = w180Grad;
            drehwinkel.y = w90Grad;
            drehwinkel.z = 0;
          }
        }

        if (typ === 'zMax') {

          p1.z = Number(item.get('translations').objectAt(0).get('z')) / 10;;
          p2.z = Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 20;

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            p1.x = 0;
            p2.x = 0;
            p1.y = 0;
            p2.y = 0;
            drehwinkel.x = -w90Grad;
            drehwinkel.y = w90Grad;
            drehwinkel.z = 0;
          } else {
            p1.x = Number(bauteile.findBy('id', name).get('translations').objectAt(0).get('x')) / 10;
            p2.x = Number(bauteile.findBy('id', name).get('translations').objectAt(0).get('x')) / 10;
            p1.y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10);
            p2.y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10);
            drehwinkel.x = w180Grad;
            drehwinkel.y = w90Grad;
            drehwinkel.z = 0;
          }
        }

        if (typ === 'zzz') {

          p1.z = -Number(bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z')) / 20;
          p2.z = Number(item.get('translations').objectAt(0).get('z')) / 10;;

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            p1.x = 0;
            p2.x = 0;
            p1.y = 0;
            p2.y = 0;
            drehwinkel.x = -w90Grad;
            drehwinkel.y = w90Grad;
            drehwinkel.z = 0;
          } else {
            p1.x = Number(bauteile.findBy('id', name).get('translations').objectAt(0).get('x')) / 10;
            p2.x = Number(bauteile.findBy('id', name).get('translations').objectAt(0).get('x')) / 10;
            p1.y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10);
            p2.y = -Number(bauteile.findBy('id', 'auflager').get('boxsizes').objectAt(0).get('y') / 10);
            drehwinkel.x = w180Grad;
            drehwinkel.y = w90Grad;
            drehwinkel.z = 0;
          }
        }

        cords = this.getMassketteKoordinaten(p1, p2, drehachsen, drehwinkel);
        // console.log(item.id +' '+ typ +' cords: '+cords);
      }

      if (name === 'koordinatensystem') {
        cords = '-5 0 0 5 0 0 0 -5 0 0 5 0 0 0 -5 0 0 5';
      }

      if (name.substring(0, 11) === 'lastenPfeil') {
        cords = '0 0 0 0 1 0';
      }

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.skalierungsfaktor', 'model.firstObject.viewpointHelper'),

    koordinatenIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";

      var item = bauteile.findBy('id', name);

      if (item.get('typ') === 'box') {

        if (typ === 'kontur' && name === 'traeger' && item.get('durchbr').objectAt(0).get('istKreis')) {

          values = "0 3 2 1 0 -1 4 5 6 7 4 -1 0 4 -1 1 5 -1 2 6 -1 3 7 -1" + " " + this.kreisDurchbrKoordindex();
        } else if (typ === 'kontur' && name === 'traeger' && !item.get('durchbr').objectAt(0).get('istKreis')) {

          values = "0 3 2 1 0 -1 4 5 6 7 4 -1 0 4 -1 1 5 -1 2 6 -1 3 7 -1" + " " + this.quadratDurchbrKoordindex();
        } else if (typ === 'kontur' && name === 'auflager') {
          values = "0 3 2 1 0 -1 0 1 4 5 0 -1 2 3 5 4 2 -1 1 2 4 1 -1 0 5 3 0 -1";
        }

        if (typ === 'faserrichtung') {
          values = "0 1 -1 1 2 -1 2 3 -1";
        }
      } else {
        if (name.substring(0, 11) === 'lastenPfeil') {
          values = "0 1 -1";
        }

        if (typ === 'hWinkel') {
          values = "0 1 -1 0 2 -1";
        }
        if (typ === 'vWinkel') {
          values = "0 1 -1 0 2 -1";
        }

        if (item.get('typ') === 'verbindungsmittel' && x3d.get('ergebnisGeladen')) {
          if (typ === 'xPos' && bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else if (typ === 'zPos' && bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            values = "0 0";
          } else {
            values = "1 2 -1 0 3 -1 4 5 -1 6 7 -1 8 9 -1";
          }
        }

        if (name === 'koordinatensystem') {
          values = "0 1 -1 2 3 -1 4 5 -1";
        }
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    istLastenpfeil: (function () {
      var istLastenpfeil = false;

      if (this.get('name').substring(0, 11) === 'lastenPfeil') {
        istLastenpfeil = true;
      }
      return istLastenpfeil;
    }).property(''),

    emissivecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', name);

      var emsvcolor = '0 0 0';

      if (x3d.get('htbreiteAktiv') === true && name === 'traeger' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hthoeheAktiv') === true && name === 'traeger' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('resthoeheobenAktiv') === true && name === 'traeger' && typ === 'resthoeheoben') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('resthoeheuntenAktiv') === true && name === 'traeger' && typ === 'resthoeheunten') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('abstandauflagerAktiv') === true && name === 'traeger' && typ === 'auflagerabstand') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('vorholzlaengeAktiv') === true && name === 'traeger' && typ === 'vorholzlaenge') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('durchbruchlaengeAktiv') === true && name === 'traeger' && typ === 'durchbruchlaenge') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('querkraftAktiv') === true && (name === 'lastenPfeil1' || name === 'lastenPfeil2')) {
        emsvcolor = '1 0 0';
      }

      if (name === 'koordinatensystem') {
        emsvcolor = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
      }

      return emsvcolor;
    }).property('model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.resthoeheobenAktiv', 'model.firstObject.resthoeheuntenAktiv', 'model.firstObject.durchbruchlaengeAktiv', 'model.firstObject.vorholzlaengeAktiv', 'model.firstObject.abstandauflagerAktiv', 'model.firstObject.querkraftAktiv'),

    getKonturKoordinaten: function getKonturKoordinaten(x, y, z) {

      var p0 = -x / 2 + ' ' + 0 + ' ' + -z / 2;
      var p1 = -x / 2 + ' ' + 0 + ' ' + z / 2;
      var p2 = x / 2 + ' 0 ' + z / 2;
      var p3 = x / 2 + ' 0 ' + -z / 2;
      var p4 = -x / 2 + ' ' + y + ' ' + -z / 2;
      var p5 = -x / 2 + ' ' + y + ' ' + z / 2;
      var p6 = x / 2 + ' ' + y + ' ' + z / 2;
      var p7 = x / 2 + ' ' + y + ' ' + -z / 2;
      var p8 = 0;
      var p9 = 0;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return koordinaten;
    },

    getMassketteKoordinaten: function getMassketteKoordinaten(punkt1, punkt2, drehachsen, drehwinkel) {

      var name = this.get('name');
      var typ = this.get('typ');

      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor') + 1;
      var skalierungsfaktor = 1;

      var b05 = 0.05 * skalierungsfaktor;
      var b1 = 0.1 * skalierungsfaktor;
      var b5 = 0.5 * skalierungsfaktor;
      var b45 = b5 - b05;
      var b55 = b5 + b05;
      var b6 = 0.6 * skalierungsfaktor;

      var hwinkel = Number(this.get('model').objectAt(0).get('hWinkel')) * Math.PI / 180;
      var vwinkel = Number(this.get('model').objectAt(0).get('vWinkel')) * Math.PI / 180;

      var dp = new Object();
      var deltaX = 0;
      var deltaY = 0;

      if (name === 'dachgeometrie' && typ === 'hoehe') {
        var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
        deltaX = -item.get('gebaeudemasse').objectAt(0).get('giebelueberstand');
      }

      if (name === 'dachgeometrie' && typ === 'traufeueberstand') {
        var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
        deltaY = -Number(item.get('gebaeudemasse').objectAt(0).get('traufeueberstand')) * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180);
      }

      if (name === 'dachgeometrie' && typ === 'firstueberstand') {
        var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
        deltaY = -Number(item.get('gebaeudemasse').objectAt(0).get('traufeueberstand')) * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung') * Math.PI / 180);
      }

      if (name === 'dachgeometrie' && typ === 'giebelueberstand' && this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('gebaeudemasse').objectAt(0).get('ausfuehrung') === 'walmdach') {
        var item = this.get('model').objectAt(0).get('bauteile').findBy('id', name);
        deltaY = -(item.get('gebaeudemasse').objectAt(0).get('giebelueberstand') / 2) * Math.tan(item.get('gebaeudemasse').objectAt(0).get('dachneigung2') * Math.PI / 180) - 0.1;
      }

      var p0 = Number(punkt1.x) + ' ' + punkt1.y + ' ' + punkt1.z;
      var p1 = Number(punkt2.x) + ' ' + (Number(punkt2.y) + Number(deltaY)) + ' ' + punkt2.z;

      dp = this.drehungXAchse({ x: 0, y: b6, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p2 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);
      var p3 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);

      dp = this.drehungXAchse({ x: b1, y: b5, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p4 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);

      dp = this.drehungXAchse({ x: -b1, y: b5, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p5 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      dp = this.drehungXAchse({ x: -b05, y: b45, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p6 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p8 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      dp = this.drehungXAchse({ x: b05, y: b55, z: 0 }, drehwinkel.x);
      dp = this.drehungYAchse(dp, drehwinkel.y);
      dp = this.drehungZAchse(dp, drehwinkel.z);
      var p7 = punkt1.x + dp.x + deltaX + ' ' + (punkt1.y + dp.y) + ' ' + (punkt1.z + dp.z);
      var p9 = punkt2.x + dp.x + deltaX + ' ' + (punkt2.y + dp.y) + ' ' + (punkt2.z + dp.z);

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + p8 + ', ' + p9;

      return koordinaten;
    },

    drehungXAchse: function drehungXAchse(v, omega) {
      var r = new Object();
      r.x = v.x;
      r.y = v.y * Math.cos(omega) - v.z * Math.sin(omega);
      r.z = v.y * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },

    drehungYAchse: function drehungYAchse(v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) + v.z * Math.sin(omega);
      r.y = v.y;
      r.z = -v.x * Math.sin(omega) + v.z * Math.cos(omega);
      return r;
    },

    drehungZAchse: function drehungZAchse(v, omega) {
      var r = new Object();
      r.x = v.x * Math.cos(omega) - v.y * Math.sin(omega);
      r.y = v.x * Math.sin(omega) + v.y * Math.cos(omega);
      r.z = v.z;
      return r;
    },

    getTraegerKoordinaten: function getTraegerKoordinaten() {
      var p0, p1, p2, p3, p4, p5, p6, p7;

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '';

      var item = bauteile.findBy('id', 'traeger');

      var x = item.get('boxsizes').objectAt(0).get('x') / 10;
      var y = item.get('boxsizes').objectAt(0).get('y') / 10;
      var z = item.get('boxsizes').objectAt(0).get('z') / 10;

      p0 = 0 + ' ' + 0 + ' ' + -(z / 2);
      p1 = 0 + ' ' + 0 + ' ' + z / 2;
      p2 = x + ' ' + 0 + ' ' + z / 2;
      p3 = x + ' ' + 0 + ' ' + -(z / 2);
      p4 = 0 + ' ' + y + ' ' + -(z / 2);
      p5 = 0 + ' ' + y + ' ' + z / 2;
      p6 = x + ' ' + y + ' ' + z / 2;
      p7 = x + ' ' + y + ' ' + -z / 2;
      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7;

      if (name === 'traeger' && item.get('durchbr').objectAt(0).get('istKreis')) {
        koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + this.kreisDurchbruchKoordinaten();
      } else if (name === 'traeger' && !item.get('durchbr').objectAt(0).get('istKreis')) {
        koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + this.quadratDurchbruchKoordinaten();
      }

      return koordinaten;
    },

    getAuflagerKoordinaten: function getAuflagerKoordinaten() {
      var p0, p1, p2, p3, p4, p5;

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '';

      var item = bauteile.findBy('id', 'auflager');

      var x = item.get('boxsizes').objectAt(0).get('x') / 10;
      var y = item.get('boxsizes').objectAt(0).get('y') / 10;
      var z = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z') / 10;

      p0 = -x / 2 + ' ' + 0 + ' ' + -z / 2;
      p1 = -x / 2 + ' ' + 0 + ' ' + z / 2;
      p2 = x / 2 + ' ' + 0 + ' ' + z / 2;
      p3 = x / 2 + ' ' + 0 + ' ' + -z / 2;

      p4 = 0 + ' ' + y + ' ' + z / 2;
      p5 = 0 + ' ' + y + ' ' + -z / 2;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5;
      return koordinaten;
    },

    quadratDurchbruchKoordinaten: function quadratDurchbruchKoordinaten() {
      var p0, p1, p2, p3, p4, p5, p6, p7;

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var y = item.get('boxsizes').objectAt(0).get('y') / 10;
      var z = item.get('boxsizes').objectAt(0).get('z') / 10;

      var vorholzlaenge = item.get('durchbr').objectAt(0).get('vorholzlaenge') / 10;
      var durchbruchlaenge = Number(item.get('durchbr').objectAt(0).get('durchbruchlaenge')) / 10;
      var resthoeheoben = item.get('durchbr').objectAt(0).get('resthoeheoben') / 10;
      var resthoeheunten = item.get('durchbr').objectAt(0).get('resthoeheunten') / 10;

      p0 = vorholzlaenge + ' ' + resthoeheunten + ' ' + -z / 2;
      p1 = vorholzlaenge + ' ' + resthoeheunten + ' ' + z / 2;
      p2 = vorholzlaenge + durchbruchlaenge + ' ' + resthoeheunten + ' ' + z / 2;
      p3 = vorholzlaenge + durchbruchlaenge + ' ' + resthoeheunten + ' ' + -z / 2;
      p4 = vorholzlaenge + ' ' + (y - resthoeheoben) + ' ' + -z / 2;
      p5 = vorholzlaenge + ' ' + (y - resthoeheoben) + ' ' + z / 2;
      p6 = vorholzlaenge + durchbruchlaenge + ' ' + (y - resthoeheoben) + ' ' + z / 2;
      p7 = vorholzlaenge + durchbruchlaenge + ' ' + (y - resthoeheoben) + ' ' + -z / 2;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7;
      return koordinaten;
    },

    quadratDurchbrKoordindex: function quadratDurchbrKoordindex() {

      var vorderseite = "9 10 14 13 9 -1";
      var rueckseite = "8 12 15 11 8 -1";
      var innenseiten = "8 9 -1 10 11 -1 14 15 -1 12 13 -1";

      var values = vorderseite + " " + rueckseite + " " + innenseiten;

      return values;
    },

    kreisDurchbruchKoordinaten: function kreisDurchbruchKoordinaten() {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '';

      var item = bauteile.findBy('id', name);

      var kreissegmete = item.get('durchbr').objectAt(0).get('kreissegmente');

      var segmentWinkel = 2 * Math.PI / kreissegmete;

      var d = item.get('durchbr').objectAt(0).get('d') / 10;
      var radius = d / 2;

      var vorholzlaenge = item.get('durchbr').objectAt(0).get('vorholzlaenge') / 10;
      var durchbruchlaenge = Number(item.get('durchbr').objectAt(0).get('durchbruchlaenge')) / 10;
      var resthoeheoben = item.get('durchbr').objectAt(0).get('resthoeheoben') / 10;
      var resthoeheunten = item.get('durchbr').objectAt(0).get('resthoeheunten') / 10;

      var z = item.get('boxsizes').objectAt(0).get('z') / 10;
      var koordinaten = vorholzlaenge + d + ' ' + (resthoeheunten + radius) + ' ' + z / 2;

      //DurchbruchKoordinaten Vorderseite
      for (var i = 1; i < kreissegmete; i++) {
        koordinaten = koordinaten + ', ' + (vorholzlaenge + radius + Math.cos(i * segmentWinkel) * radius) + ' ' + (resthoeheunten + radius + Math.sin(i * segmentWinkel) * radius) + ' ' + z / 2;
      }

      koordinaten = koordinaten + ', ' + (vorholzlaenge + d) + ' ' + (resthoeheunten + radius) + ' ' + -(z / 2);

      //Durchbruchkoordinaten Rückseite
      for (var i = 1; i < kreissegmete; i++) {
        koordinaten = koordinaten + ', ' + (vorholzlaenge + radius + Math.cos(i * segmentWinkel) * radius) + ' ' + (resthoeheunten + radius + Math.sin(i * segmentWinkel) * radius) + ' ' + -(z / 2);
      }

      return koordinaten;
    },

    kreisDurchbrKoordindex: function kreisDurchbrKoordindex() {

      var vorderseite = this.kordIndexVorderseite();
      var rueckseite = this.kordIndexRueckseite();

      var values = vorderseite + ' ' + rueckseite;

      return values;
    },

    kordIndexVorderseite: function kordIndexVorderseite() {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '';

      var item = bauteile.findBy('id', name);

      var kreissegmete = item.get('durchbr').objectAt(0).get('kreissegmente');

      for (var i = 8; i < kreissegmete + 8; i++) {
        values = values + ' ' + i;
      }

      values = values + ' 8 -1';

      return values;
    },

    kordIndexRueckseite: function kordIndexRueckseite() {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var kreissegmete = item.get('durchbr').objectAt(0).get('kreissegmente');

      var values = '';

      for (var i = kreissegmete + 8; i < kreissegmete * 2 + 8; i++) {
        values = values + ' ' + i;
      }

      values = values + ' ' + (kreissegmete + 8) + ' -1';

      return values;
    }

  });

  exports['default'] = Transform;

});