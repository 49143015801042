define('m09/routes/queranschluss', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    i18n: Ember['default'].inject.service(),
    hn: Ember['default'].computed('i18n.locale', function () {

      return this.get('i18n').t('haupt_nebentraeger');
    }),

    model: function model() {
      return this.store.find('queranschluss');
    },

    setupController: function setupController() {
      this.controllerFor('application').miniertX3D();
    }

  });

});