define('m09/components/con-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Cone = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'con-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: [''],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    translation: (function () {
      var value = '0 0 0';

      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', this.get('name'));
      var bauteile = x3d.get('bauteile');

      var deltaX = 2 - 2 * Math.cos(20 * Math.PI / 180);
      var deltaY = 2 * Math.sin(20 * Math.PI / 180);
      var deltaZ = 0;

      if (this.get('name') === 'lastenPfeil3') {
        value = -2 + deltaX + ' ' + deltaY + ' ' + 0;
      } else if (this.get('name') === 'lastenPfeil4') {
        value = 2 - deltaX + ' ' + deltaY + ' ' + 0;
      } else if (this.get('name') === 'koordinatensystem') {

        if (this.get('typ') === 'x') {
          deltaX = item.get('boxsizes').objectAt(0).get('x');
          deltaY = 0;
          deltaZ = 0;
        } else if (this.get('typ') === 'y') {
          deltaX = 0;
          deltaY = item.get('boxsizes').objectAt(0).get('y');
          deltaZ = 0;
        } else if (this.get('typ') === 'z') {
          deltaX = 0;
          deltaY = 0;
          deltaZ = item.get('boxsizes').objectAt(0).get('z');
        }

        value = +deltaX + ' ' + deltaY + ' ' + deltaZ;
      }
      return value;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var value = '1 0 0 3.14159';

      if (this.get('name') === 'lastenPfeil4') {
        value = '0 0 1 ' + 20 * Math.PI / 180;
      } else if (this.get('name') === 'lastenPfeil3') {
        value = '0 0 1 ' + -20 * Math.PI / 180;
      } else if (this.get('name') === 'koordinatensystem') {
        if (this.get('typ') === 'x') {
          value = '0 0 1 -1.5708';
        } else if (this.get('typ') === 'y') {
          value = '0 0 0 0';
        } else if (this.get('typ') === 'z') {
          value = '1 0 0 1.5708';
        }
      }

      return value;
    }).property(''),

    coneTagID: (function () {
      return 'SpitzeLastenpfeil' + this.get('name').replace('lastenpfeil', '');
    }).property(''),

    coneBottomradius: (function () {
      return '0.075';
    }).property(''),

    coneHeight: (function () {
      return '0.2';
    }).property(''),

    emissivecolor: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var item = x3d.get('bauteile').findBy('id', this.get('name'));

      var emsvcolor = '0 0 0';

      if (x3d.get('querkraftAktiv') === true && (name === 'lastenPfeil1' || name === 'lastenPfeil2')) {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('momentlastAktiv') === true && (name === 'lastenPfeil3' || name === 'lastenPfeil4')) {
        emsvcolor = '1 0 0';
      }

      if (name === 'koordinatensystem') {
        emsvcolor = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
      }

      return emsvcolor;
    }).property('model.firstObject.querkraftAktiv', 'model.firstObject.momentlastAktiv')

  });

  exports['default'] = Cone;

});