define('m09/components/indexedfacese-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Transform = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'indexedfacese-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    objektSkalierung: 1,

    indexHelper: false,

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var item = bauteile.findBy('id', name);

      var x = 0;
      var y = 0;
      var z = 0;

      var cords = '0 0 0';

      return cords;
    }).property('model.firstObject.transformHelper'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var item = bauteile.findBy('id', name);
      var values = '0 0 0 0';

      return values;
    }).property('model.firstObject.transformHelper'),

    koordinaten: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');

      var cords = '0 0 0 0 0 0 0 0 0 0 0 0';

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (name === 'traeger') {

        cords = this.getTraegerKoordinaten();
      } else if (name === 'auflager') {
        cords = this.getAuflagerKoordinaten();
      }

      this.set('indexHelper', !this.get('indexHelper'));

      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.skalierungsfaktor'),

    koordinatenIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = '';

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (name === 'traeger' && item.get('durchbr').objectAt(0).get('istKreis')) {

        values = "0 3 2 1 -1 4 5 6 7 -1 0 1 5 4 -1 2 3 7 6 -1 " + this.kreisDurchbrKoordindex();
      } else if (name === 'traeger' && !item.get('durchbr').objectAt(0).get('istKreis')) {

        values = "0 3 2 1 -1 4 5 6 7 -1 0 1 5 4 -1 2 3 7 6 -1 " + this.quadratDurchbrKoordindex();
      } else if (name === 'auflager') {
        values = "0 3 2 1 0 -1 0 1 4 5 0 -1 2 3 5 4 2 -1 1 2 4 1 -1 0 5 3 0 -1";
      }

      return values;
    }).property('indexHelper'),

    farbIndex: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = '0 0 0 0 0';
      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      if (name === 'traeger' && item.get('durchbr').objectAt(0).get('istKreis')) {
        values = '0 0 0 0';
        var kreissegmete = item.get('durchbr').objectAt(0).get('kreissegmente');
        var zusaetzlicheFlaechen = (kreissegmete + 4) * 2 + kreissegmete;
        for (var i = 1; i <= zusaetzlicheFlaechen; i++) {
          values = values + ' 0';
        }
      } else if (name === 'traeger' && !item.get('durchbr').objectAt(0).get('istKreis')) {
        values = '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0';
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt'),

    farbe: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = '';

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      // var values ='1 0 0, 0.66 0 0.07, 0.85 0.85 0.85';

      var values = item.get('diffusecolors').objectAt(0).get('rot') + ' ' + item.get('diffusecolors').objectAt(0).get('gruen') + ' ' + item.get('diffusecolors').objectAt(0).get('blau');

      return values;
    }).property('model.firstObject.transformHelper'),

    getTraegerKoordinaten: function getTraegerKoordinaten() {
      var p0, p1, p2, p3, p4, p5, p6, p7;

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model');
      var bauteile = x3d.objectAt(0).get('bauteile');
      var values = '';

      var item = x3d.objectAt(0).get('bauteile').findBy('id', name);

      var x = item.get('boxsizes').objectAt(0).get('x') / 10;
      var y = item.get('boxsizes').objectAt(0).get('y') / 10;
      var z = item.get('boxsizes').objectAt(0).get('z') / 10;

      p0 = 0 + ' ' + 0 + ' ' + -(z / 2);
      p1 = 0 + ' ' + 0 + ' ' + z / 2;
      p2 = x + ' ' + 0 + ' ' + z / 2;
      p3 = x + ' ' + 0 + ' ' + -(z / 2);
      p4 = 0 + ' ' + y + ' ' + -(z / 2);
      p5 = 0 + ' ' + y + ' ' + z / 2;
      p6 = x + ' ' + y + ' ' + z / 2;
      p7 = x + ' ' + y + ' ' + -z / 2;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7;

      if (name === 'traeger' && item.get('durchbr').objectAt(0).get('istKreis')) {
        koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + this.kreisDurchbruchKoordinaten();
      } else if (name === 'traeger' && !item.get('durchbr').objectAt(0).get('istKreis')) {
        koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7 + ', ' + this.quadratDurchbruchKoordinaten();
      }

      return koordinaten;
    },

    getAuflagerKoordinaten: function getAuflagerKoordinaten() {
      var p0, p1, p2, p3, p4, p5;

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '';

      var item = bauteile.findBy('id', 'auflager');

      var x = item.get('boxsizes').objectAt(0).get('x') / 10;
      var y = item.get('boxsizes').objectAt(0).get('y') / 10;
      var z = bauteile.findBy('id', 'traeger').get('boxsizes').objectAt(0).get('z') / 10;

      p0 = -x / 2 + ' ' + 0 + ' ' + -z / 2;
      p1 = -x / 2 + ' ' + 0 + ' ' + z / 2;
      p2 = x / 2 + ' ' + 0 + ' ' + z / 2;
      p3 = x / 2 + ' ' + 0 + ' ' + -z / 2;

      p4 = 0 + ' ' + y + ' ' + z / 2;
      p5 = 0 + ' ' + y + ' ' + -z / 2;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5;
      return koordinaten;
    },

    kreisDurchbruchKoordinaten: function kreisDurchbruchKoordinaten() {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '';

      var item = bauteile.findBy('id', name);

      var kreissegmete = item.get('durchbr').objectAt(0).get('kreissegmente');

      var segmentWinkel = 2 * Math.PI / kreissegmete;

      var d = item.get('durchbr').objectAt(0).get('d') / 10;
      var radius = d / 2;

      var vorholzlaenge = item.get('durchbr').objectAt(0).get('vorholzlaenge') / 10;
      var durchbruchlaenge = Number(item.get('durchbr').objectAt(0).get('durchbruchlaenge')) / 10;
      var resthoeheoben = item.get('durchbr').objectAt(0).get('resthoeheoben') / 10;
      var resthoeheunten = item.get('durchbr').objectAt(0).get('resthoeheunten') / 10;

      var z = item.get('boxsizes').objectAt(0).get('z') / 10;
      var koordinaten = vorholzlaenge + d + ' ' + (resthoeheunten + radius) + ' ' + z / 2;

      //DurchbruchKoordinaten Vorderseite
      for (var i = 1; i < kreissegmete; i++) {
        koordinaten = koordinaten + ', ' + (vorholzlaenge + radius + Math.cos(i * segmentWinkel) * radius) + ' ' + (resthoeheunten + radius + Math.sin(i * segmentWinkel) * radius) + ' ' + z / 2;
      }

      koordinaten = koordinaten + ', ' + (vorholzlaenge + d) + ' ' + (resthoeheunten + radius) + ' ' + -(z / 2);

      //Durchbruchkoordinaten Rückseite
      for (var i = 1; i < kreissegmete; i++) {
        koordinaten = koordinaten + ', ' + (vorholzlaenge + radius + Math.cos(i * segmentWinkel) * radius) + ' ' + (resthoeheunten + radius + Math.sin(i * segmentWinkel) * radius) + ' ' + -(z / 2);
      }

      return koordinaten;
    },

    quadratDurchbruchKoordinaten: function quadratDurchbruchKoordinaten() {
      var p0, p1, p2, p3, p4, p5, p6, p7;

      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var y = item.get('boxsizes').objectAt(0).get('y') / 10;
      var z = item.get('boxsizes').objectAt(0).get('z') / 10;

      var vorholzlaenge = item.get('durchbr').objectAt(0).get('vorholzlaenge') / 10;
      var durchbruchlaenge = Number(item.get('durchbr').objectAt(0).get('durchbruchlaenge')) / 10;
      var resthoeheoben = item.get('durchbr').objectAt(0).get('resthoeheoben') / 10;
      var resthoeheunten = item.get('durchbr').objectAt(0).get('resthoeheunten') / 10;

      p0 = vorholzlaenge + ' ' + resthoeheunten + ' ' + -z / 2;
      p1 = vorholzlaenge + ' ' + resthoeheunten + ' ' + z / 2;
      p2 = vorholzlaenge + durchbruchlaenge + ' ' + resthoeheunten + ' ' + z / 2;
      p3 = vorholzlaenge + durchbruchlaenge + ' ' + resthoeheunten + ' ' + -z / 2;
      p4 = vorholzlaenge + ' ' + (y - resthoeheoben) + ' ' + -z / 2;
      p5 = vorholzlaenge + ' ' + (y - resthoeheoben) + ' ' + z / 2;
      p6 = vorholzlaenge + durchbruchlaenge + ' ' + (y - resthoeheoben) + ' ' + z / 2;
      p7 = vorholzlaenge + durchbruchlaenge + ' ' + (y - resthoeheoben) + ' ' + -z / 2;

      var koordinaten = p0 + ', ' + p1 + ', ' + p2 + ', ' + p3 + ', ' + p4 + ', ' + p5 + ', ' + p6 + ', ' + p7;
      return koordinaten;
    },

    quadratDurchbrKoordindex: function quadratDurchbrKoordindex() {

      var vorderseite = "9 1 2 10 -1 10 2 6 14 -1 14 6 5 13 -1 13 5 1 9 -1";
      var rueckseite = "0 8 11 3 -1 3 11 15 7 -1 4 7 15 12 -1 0 4 12 8 -1";
      var innenseiten = "8 12 13 9 -1 8 9 10 11 -1 11 10 14 15 -1 12 15 14 13 -1";

      var values = vorderseite + " " + rueckseite + " " + innenseiten;

      return values;
    },

    kreisDurchbrKoordindex: function kreisDurchbrKoordindex() {

      // var vorderseite = "2 17 1 -1 2 18 17 -1 2 19 18 -1 2 8 19 -1 6 8 2 -1 6 9 8 -1 6 10 9 -1 6 11 10 -1 5 11 6 -1 5 12 11 -1 5 13 12 -1 5 14 13 -1 1 14 5 -1 1 15 14 -1 1 16 15 -1 1 17 16 -1";
      var vorderseite = this.kordIndexVorderseite();
      var rueckseite = this.kordIndexRueckseite();
      var innenseiten = this.kordIndexInnenseite();

      var values = vorderseite + ' ' + rueckseite + ' ' + innenseiten;

      return values;
    },

    kordIndexVorderseite: function kordIndexVorderseite() {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var values = '6 8 2 -1';

      var item = bauteile.findBy('id', name);

      var kreissegmete = item.get('durchbr').objectAt(0).get('kreissegmente');

      for (var i = 9; i < kreissegmete + 8; i++) {
        if (i < kreissegmete / 4 + 8) {
          values = values + ' 6 ' + i + ' ' + (i - 1) + ' -1';
        } else if (i === kreissegmete / 4 + 8) {
          values = values + ' 6 ' + i + ' ' + (i - 1) + ' -1';
          values = values + ' 5 ' + (kreissegmete / 4 + 8) + ' 6 -1';
        } else if (i < kreissegmete / 2 + 8 && i > kreissegmete / 4 + 8) {
          values = values + ' 5 ' + i + ' ' + (i - 1) + ' -1';
        } else if (i === kreissegmete / 2 + 8) {
          values = values + ' 5 ' + i + ' ' + (i - 1) + ' -1';
          values = values + ' 1 ' + (kreissegmete / 2 + 8) + ' 5 -1';
        } else if (i < kreissegmete * 3 / 4 + 8 && i > kreissegmete / 2 + 8) {
          values = values + ' 1 ' + i + ' ' + (i - 1) + ' -1';
        } else if (i === kreissegmete * 3 / 4 + 8) {
          values = values + ' 1 ' + i + ' ' + (i - 1) + ' -1';
          values = values + ' 2 ' + (kreissegmete * 3 / 4 + 8) + ' 1 -1';
        } else if (i < kreissegmete + 8 && i > kreissegmete * 3 / 4 + 8) {
          values = values + ' 2 ' + i + ' ' + (i - 1) + ' -1';
        }
      }

      values = values + ' 2 8 ' + (kreissegmete - 1 + 8) + ' -1';

      return values;
    },

    kordIndexRueckseite: function kordIndexRueckseite() {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var kreissegmete = item.get('durchbr').objectAt(0).get('kreissegmente');

      var values = '3 ' + (kreissegmete + 8) + ' 7 -1';

      for (var i = kreissegmete + 9; i < kreissegmete * 2 + 8; i++) {
        if (i < kreissegmete / 4 + kreissegmete + 8) {
          values = values + ' 7 ' + (i - 1) + ' ' + i + ' -1';
        } else if (i === kreissegmete / 4 + kreissegmete + 8) {
          values = values + ' 7 ' + (i - 1) + ' ' + i + ' -1';
          values = values + ' 7 ' + (kreissegmete / 4 + kreissegmete + 8) + ' 4 -1';
        } else if (i < kreissegmete / 2 + kreissegmete + 8 && i > kreissegmete / 4 + kreissegmete + 8) {
          values = values + ' 4 ' + (i - 1) + ' ' + i + ' -1';
        } else if (i === kreissegmete / 2 + kreissegmete + 8) {
          values = values + ' 4 ' + (i - 1) + ' ' + i + ' -1';
          values = values + ' 4 ' + (kreissegmete / 2 + kreissegmete + 8) + ' 0 -1';
        } else if (i < kreissegmete * 3 / 4 + kreissegmete + 8 && i > kreissegmete / 2 + kreissegmete + 8) {
          values = values + ' 0 ' + (i - 1) + ' ' + i + ' -1';
        } else if (i === kreissegmete * 3 / 4 + kreissegmete + 8) {
          values = values + ' 0 ' + (i - 1) + ' ' + i + ' -1';
          values = values + ' 0 ' + (kreissegmete * 3 / 4 + kreissegmete + 8) + ' 3 -1';
        } else if (i < kreissegmete + kreissegmete + 8 && i > kreissegmete * 3 / 4 + kreissegmete + 8) {
          values = values + ' 3 ' + (i - 1) + ' ' + i + ' -1';
        }
      }

      values = values + ' 3 ' + (kreissegmete + 8 + (kreissegmete - 1)) + ' ' + (kreissegmete + 8) + ' -1';

      return values;
    },

    kordIndexInnenseite: function kordIndexInnenseite() {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var kreissegmete = item.get('durchbr').objectAt(0).get('kreissegmente');

      var values = ' ';

      for (var i = 8; i < kreissegmete + 7; i++) {
        values = values + i + ' ' + (i + 1) + ' ' + (i + 1 + kreissegmete) + ' ' + (i + kreissegmete) + ' -1 ';
      }

      values = values + i + ' ' + 8 + ' ' + (8 + kreissegmete) + ' ' + (i + kreissegmete) + ' -1 ';

      return values;
    }

  });

  exports['default'] = Transform;

});