define('m09/models/durchbruch', ['exports', 'ember-data'], function (exports, DS) {

	'use strict';

	var durchbruch = DS['default'].Model.extend({

		RechteckForm: DS['default'].attr('boolean'),
		L_V: DS['default'].attr('string'),
		L_A: DS['default'].attr('string'),
		h_ro: DS['default'].attr('string'),
		h_ru: DS['default'].attr('string'),
		a: DS['default'].attr('string'),
		h_d: DS['default'].attr('string')
	});

	durchbruch.reopenClass({
		FIXTURES: [{
			id: 1,
			RechteckForm: true,
			L_V: "0",
			L_A: "0",
			h_ro: "0",
			h_ru: "0",
			a: "0",
			h_d: "0"
		}]
	});

	exports['default'] = durchbruch;

});